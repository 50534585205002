/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import Checkbox from '../../../components/shared/form-control/Checkbox';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import AuthService from '../../../services/AuthService';
import { FlowProps } from './RegisterFlow';
import { FieldRenderer } from '../../../components/user/FieldRenderer';
import { UserClientSpecificCustomData, UserProfile } from '../../../models/AuthModels';
import { MemberField, MemberFieldKey } from '../../../models/ClientMemberFields';
import i18next from 'i18next';
import { useRegistrationClientId } from '../../../global-state/Clients';

const RegisterDetails: FC<FlowProps> = (props) => {
  const { next, userEmail, memberFields, user } = props;
  const registrationClientId = useRegistrationClientId((x) => x.value);
  const [formState, setFormState] = useState<UserProfile>({} as UserProfile);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [tcAgree, setTcAgree] = useState(false);

  const { t } = useTranslation(['auth', 'common']);

  useEffect(() => {
    setFormState({
      email: userEmail,
      firstName: user.firstName,
      lastName: user.lastName,
      languageCode: user.language,
      phoneNumber: user.phoneNumber,
      birthday: user.birthday,
      address: user.address,
      userImageId: user.userImageId,
      clientCustomData: {
        ...(user.clientCustomData ?? {}),
        [registrationClientId as string]: {
          departmentId: user.clientCustomData?.[registrationClientId as string]?.departmentId,
          positionId: user.clientCustomData?.[registrationClientId as string]?.positionId,
          groupId: user.clientCustomData?.[registrationClientId as string]?.groupId,
          startDate: user.clientCustomData?.[registrationClientId as string]?.startDate,
          employeeId: user.clientCustomData?.[registrationClientId as string]?.employeeId,
          officeLocation: user.clientCustomData?.[registrationClientId as string]?.officeLocation,
        },
      },
    });
  }, [registrationClientId, user, userEmail]);

  const handleFieldChange = useCallback(
    (key: string, value: any, _isRequired: boolean, fieldInfo: MemberField) => {
      if (fieldInfo.isUserField) {
        setFormState((prev) => ({ ...prev, [key]: value }));
      } else {
        setFormState((prev) => ({
          ...prev,
          clientCustomData: {
            ...(prev.clientCustomData ?? {}),
            [registrationClientId as string]: {
              ...(prev.clientCustomData?.[registrationClientId as string] ?? {}),
              [key]: value,
            },
          },
        }));
      }
      setErrors((prev) => ({ ...prev, [key]: '' }));
    },
    [registrationClientId],
  );

  const validateField = useCallback(
    (key: string, value: any, isRequired: boolean): string | null => {
      if (isRequired && !value) {
        return t('common:validation.required');
      }
      return null;
    },
    [t],
  );

  const validateForm = useCallback(() => {
    const newErrors: Record<string, string> = {};

    const isValid = memberFields.every((field) => {
      // Skip validation for non-editable fields
      if (!field.isEditable || [MemberFieldKey.BuilderAccess, MemberFieldKey.Status].includes(field.key)) {
        return true;
      }

      const error = validateField(
        field.key,
        field.key in formState
          ? formState[field.key as keyof UserProfile]
          : formState.clientCustomData?.[registrationClientId!]?.[field.key as keyof UserClientSpecificCustomData],
        field.isRequired,
      );

      if (error) {
        newErrors[field.key] = error;
        return false;
      }

      return true;
    });

    setErrors(newErrors);
    setIsFormValid(isValid && tcAgree);
  }, [formState, memberFields, registrationClientId, tcAgree, validateField]);

  useEffect(() => {
    validateForm();
  }, [formState, memberFields, validateForm]);

  const saveDetails = useCallback(() => {
    if (isFormValid) {
      AuthService.setUserDetails({ ...formState, languageCode: i18next.language }).then(() => {
        next();
      });
    }
  }, [formState, isFormValid, next]);

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.details.heading')}</Heading>
      <div>{t('registration.details.subheading')}</div>
      <div>
        {memberFields.map((field) => {
          const renderer = FieldRenderer[field.key as MemberFieldKey];
          if (!renderer) return null;

          return (
            <div key={field.key} className="px-4">
              {renderer({
                field: { ...field, disabled: field.key === MemberFieldKey.Email },
                userProfile: formState,
                errors,
                onFieldChange: handleFieldChange,
                t,
                clientId: registrationClientId,
              })}
            </div>
          );
        })}
        <div>
          <Checkbox
            value={tcAgree}
            onChange={setTcAgree}
            label={
              <Trans
                t={t}
                i18nKey="registration.details.inputs.t-and-c"
                components={{
                  Link: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                    <a
                      href="/legal/terms-and-conditions"
                      data-cy="registration-terms-and-conditions-link"
                      className="cursor-pointer font-medium hover:underline"
                      target="_blank"
                    />
                  ),
                }}
              />
            }
            className="mt-4"
            data-cy="registration-terms-and-conditions"
          />
        </div>
        <div className="px-10 text-center">
          <Button className="my-6 w-full" type={ButtonType.PRIMARY} onClick={saveDetails} data-cy="register-next" disabled={!isFormValid}>
            <span className="font-medium">{t('registration.details.buttons.next')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterDetails;
